import { Controller } from "@hotwired/stimulus"

// https://stackoverflow.com/questions/143815/determine-if-an-html-elements-content-overflows
// Determines if the passed element is overflowing its bounds,
// either vertically or horizontally.
// Will temporarily modify the "overflow" style to detect this
// if necessary.
function checkOverflow(el)
{
    var curOverflow = el.style.overflow;

    if ( !curOverflow || curOverflow === "visible" )
        el.style.overflow = "hidden";

    var isOverflowing = el.clientWidth < el.scrollWidth
        || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
}

export default class extends Controller {
    static targets = ["content", "moreButton", "lessButton"]

    connect() {
        let over = checkOverflow(this.contentTarget)

        this.moreButtonTarget.classList.toggle('d-none', !over)
        this.lessButtonTarget.classList.toggle('d-none', true)
    }

    expand(e) {
        e.preventDefault();
        this.moreButtonTarget.classList.toggle('d-none', true)
        this.lessButtonTarget.classList.toggle('d-none', false)
        this.contentTarget.style.maxHeight = 'none'
    }
    collapse(e) {
        e.preventDefault();
        this.moreButtonTarget.classList.toggle('d-none', false)
        this.lessButtonTarget.classList.toggle('d-none', true)
        this.contentTarget.style.maxHeight = null
    }
}

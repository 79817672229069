    import { Controller } from "@hotwired/stimulus"

    export default class extends Controller {
      static targets = ["votesCount"]

      connect() {
        this.render()
      }

      vote() {
        let url = this.data.get('url')
        let isParticipating = this.data.has('participating')
        let count = Number.parseInt(this.data.get('count'))

        if (this.data.has('voted')) {
          if (isParticipating) {
            count -= 1
          }
          this.data.delete('voted')

          jQuery.ajax(url, {
            method: 'DELETE'
          })

        } else {
          if (isParticipating) {
            count += 1
          }
          this.data.set('voted')

          jQuery.ajax(url, {
            method: 'POST'
          })
        }

        this.data.set('count', count)

        this.render()
      }

      render() {
        this.votesCountTarget.innerHTML = this.data.get('count')

        let voted = this.data.has('voted');
        $(this.votesCountTarget).toggleClass('bg-secondary', !voted)
        $(this.votesCountTarget).toggleClass('bg-success', voted)
      }
    }


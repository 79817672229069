import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
  }

  async connect() {
    const Daily = (await import("@daily-co/daily-js")).default

    let name = this.data.get('userName')
    let email = this.data.get('userEmail')
    let room = this.data.get('roomName')
    let videoUrl = this.data.get('videoUrl')

    this.callFrame = Daily.createFrame(this.element, {
      iframeStyle: {
        border: '0px solid black',
        width: '100%',
        height: 'calc(100% + 30px)',
        'margin-top': '-30px'
      },
      showLeaveButton: false,
      showFullscreenButton: false,
    })

    this.callFrame.join({
      url: videoUrl,
      userName: name,
    })
  }

  disconnect() {
    if (this.callFrame) {
      this.callFrame.destroy()
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.toggle = this.toggle.bind(this)
    this.add = this.add.bind(this)
  }
  toggle() {
    this.list.forEach((args) => {
      document.querySelector(args[0]).classList.toggle(args[1])
    })
  }

  add() {
    this.list.forEach((args) => {
      document.querySelector(args[0]).classList.add(args[1])
    })
  }

  get list() {
    let selectors = this.data.get('selectors')
    let list = selectors.split(',')
    let result = []
    list.forEach((map) => {
      let args = map.split('->')
      result.push(args)
    })
    return result;
  }
}

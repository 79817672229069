import { Controller } from "@hotwired/stimulus"
import audioQueue from "../src/audio_queue"

export default class extends Controller {
  connect() {
  }

  initiate() {
    audioQueue.addSilence()
  }
}

import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "list", "message" ]

  connect() {
    // Scroll to last read message on load
    this.restoreScroll()

    // Scroll to bottom on new message
    this.scrollBottom()
    this.observer = new MutationObserver((mutations, observer) => {
      this.scrollBottom()
      this.updateReadStatus()
    });
    this.observer.observe(this.listTarget, {childList: true});

    // Keep track of unread
    this.visibilityHandler = () => {
      if (!this.listVisible) { return }

      this.lastScrollAtBottom = this.element.scrollTop === this.element.scrollTopMax
      this.updateReadStatus()
    }

    setTimeout(() => {
      this.updateReadStatus()
    }, 1000) // delay otherwise sometimes new message is not received on load

    this.element.addEventListener('scroll', this.visibilityHandler, false);
    this.element.addEventListener('resize', this.visibilityHandler, false);
  }

  disconnect() {
    this.observer.disconnect();

    this.element.removeEventListener('scroll', this.visibilityHandler);
    this.element.removeEventListener('resize', this.visibilityHandler);
  }

  updateReadStatus() {
    // Check list is visible
    if (!this.listVisible) { return }

    // Check we are at the bottom of the scroll,
    // scrollTopMax is undefined when there is no scroll
    if (this.element.scrollTopMax !== undefined
      && this.element.scrollTop !== this.element.scrollTopMax) { return }

    // Check last element has changed
    let children = this.listTarget.childNodes
    let lastMessageElement = children[children.length - 1]
    if (lastMessageElement === this.lastMessageElement) { return }
    this.lastMessageElement = lastMessageElement

    console.log('updateReadStatus')
    let url = this.listTarget.dataset.chatUserUrl

    fetch(url, {
      method: 'PUT',
      credentials: "same-origin",
      headers: { 'X-CSRF_Token': Rails.csrfToken() }
    })
  }

  get listVisible() {
    return this.listTarget.offsetParent !== null
  }

  scrollBottom() {
    if (!this.listVisible) { return; }
    if (this.lastScrollAtBottom === undefined || !this.lastScrollAtBottom) { return }

    this.element.scrollTop = this.element.scrollTopMax
  }

  onLoadRestoreScroll() {
    if (this.onLoadPositionRestored) { return }
    if (!this.listVisible) { return }

    let listLastId = this.listTarget.dataset.sequentialId
    this.lastMessageElement = this.messageTargets.find(el => el.dataset.sequentialId === listLastId)

    if (this.lastMessageElement) {
      this.lastScrollPosition = this.lastMessageElement.offsetTop - this.listTarget.offsetTop
    }
    this.onLoadPositionRestored = true
  }

  saveScroll() {
    this.lastScrollPosition = this.element.scrollTop
  }

  restoreScroll() {
    this.onLoadRestoreScroll()

    if (this.lastScrollPosition) {
      this.element.scrollTop = this.lastScrollPosition
    }
  }
}

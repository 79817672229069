const Tribes = {
  setAppBadge(unreadCount) {
    // Set or clear the badge.
    if (navigator.setAppBadge) {
      if (unreadCount && unreadCount > 0) {
        navigator.setAppBadge(unreadCount)
      } else {
        navigator.clearAppBadge()
      }
    }

    this.setNativeBadge(unreadCount)
  },

  async setNativeBadge(count) {
    // plugin is not released on iOS as of 2023-09-17
    return;

    if (!window.CapJS || !window.CapJS.Badge) {
      return
    }

    const result = await window.CapJS.Badge.isSupported()
    if (!result.isSupported) {
      return
    }

    if (unreadCount && unreadCount > 0) {
      window.CapJS.Badge.set({count})
    } else {
      window.CapJS.Badge.clear()
    }
  }
}

window.Tribes = Tribes

import { Controller } from "@hotwired/stimulus"
import {Dropdown} from "bootstrap"

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    url: String,
  }

  connect() {
  }

  share(e) {
    const data = {
      title: this.titleValue,
      text: this.textValue,
      url: this.urlValue,
    };

    try {
      if (navigator.share && navigator.canShare(data)) {
        navigator.share(data);

        e.preventDefault()
        e.stopImmediatePropagation()

        let dropdown = Dropdown.getInstance(this.element)
        if (dropdown) {
          dropdown.hide();
        }
      }
    } catch (err) {
      console.log(`Error: ${err}`)
    }
  }
}

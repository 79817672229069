import { Controller } from "@hotwired/stimulus"
import { set as setCookie, get as getCookie } from 'es-cookie'

export default class extends Controller {
  static values = {
    url: String,
    userId: String,
  }
  static targets = ["dismiss"]

  connect() {
    if (!window.PushNotifications) {
      return
    }
    this.init()
    this.addListeners()
  }

  disconnect() {
    if (this.registrationListener) {
      this.registrationListener.remove()
    }
    if (this.registrationErrorListener) {
      this.registrationErrorListener.remove()
    }
  }

  async addListeners() {
    window.PushNotifications.removeAllListeners()

    // On success, we should be able to receive notifications
    this.registrationListener = await window.PushNotifications
      .addListener('registration', this.registration.bind(this))

    // Some issue with our setup and push will not work
    this.registrationErrorListener = await window.PushNotifications
      .addListener('registrationError', this.registrationError.bind(this))
  }

  async init() {
    let permStatus = await window.PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      if (getCookie('nativepush') !== 'dismissed' || !this.hasDismissTarget) {
        this.show()
      }
    }

    if (permStatus.receive === 'granted') {
      let existingUserId = localStorage.getItem("nativePushUserId")
      let existingToken = localStorage.getItem("nativePushToken")

      if (existingToken && existingUserId !== this.userIdValue) {
        // registered for another user
        this.postRequest(existingToken)
        localStorage.setItem("nativePushUserId", this.userIdValue)
      }

      if (!existingToken) {
        window.PushNotifications.unregister()
        this.show()
      }
    }
  }

  registration(token) {
    console.log('Push registration success, token: ', token)

    localStorage.setItem("nativePushUserId", this.userIdValue)
    localStorage.setItem("nativePushToken", token.value)

    this.postRequest(token.value)
  }

  postRequest(token) {
    $.post(this.urlValue, {
      token: token,
      platform: CapJS.Capacitor.getPlatform()
    }).then((data) => {
      this.hide()
    })
  }

  registrationError(error) {
    console.error('Error on registration: ' + JSON.stringify(error))
  }

  requestPermission(e) {
    e.preventDefault()

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    window.PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        window.PushNotifications.register()
      } else {
        console.log(resutl)
        // Show some error
      }
    })
  }

  show() {
    this.element.classList.remove("d-none")
  }

  hide() {
    this.element.classList.add("d-none")
  }

  dismiss(e) {
    this.hide()
    setCookie('nativepush', 'dismissed', {expires: 14})
  }
}


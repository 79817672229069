import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {
    let seconds = 60

    if (this.data.has('seconds')) {
      seconds = Number.parseInt(this.data.get('seconds'))
    }

    this.intervalId = setInterval(this.heartbeat.bind(this), seconds * 1000)
    this.heartbeat()
  }

  heartbeat() {
    let url = this.data.get('url')

    jQuery.ajax(url, {
      method: 'POST'
    })
  }

  disconnect() {
    clearInterval(this.intervalId)

    if (this.data.has('lastOne')) {
      let url = this.data.get('url')

      jQuery.ajax(url, {
        method: 'DELETE'
      })
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// resize textarea to fit text
// inspired by https://stackoverflow.com/questions/454202/creating-a-textarea-with-auto-resize
export default class extends Controller {
  connect() {
    this.element.setAttribute("style", "height:" + (this.element.scrollHeight) + "px;overflow-y:hidden;");

    this.element.addEventListener("input", this.onInput, false)
  }

  disconnect() {
    this.element.removeEventListener('input', this.onInput)
  }

  onInput() {
    this.style.height = "auto";
    this.style.height = (this.scrollHeight) + "px";
  }
}

import { Controller } from "@hotwired/stimulus"
import { addAction } from "../src/helpers"

export default class extends Controller {
  static targets = ['clear']

  initialize() {
    super.initialize()
    addAction(this, "turbo:submit-end->clearform#submit")
  }

  submit(e) {
    if (e.detail.success) {
      this.clearTargets.forEach((el) => {
        el.value = null
      })
    }
  }
}

import PlacesAutocomplete from 'stimulus-places-autocomplete'
import { addAction } from "../src/helpers"

// https://www.stimulus-components.com/docs/stimulus-places-autocomplete
window.initAutocomplete = function () {
  const event = new Event('google-maps-callback', {
    bubbles: true,
    cancelable: true,
  })
  window.dispatchEvent(event)
}

export default class extends PlacesAutocomplete {
  connect() {
    addAction(this, "google-maps-callback@window->places#initAutocomplete")
    super.connect()
    this.init()
  }

  async init() {
    await import(
      /* @vite-ignore */
      `https://maps.googleapis.com/maps/api/js?key=${window.googleMapsApiKey}&libraries=places&callback=initAutocomplete`
      )
  }
}

import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { addAction } from "../src/helpers"

export default class extends Controller {
  static targets = ["content", "defaultContent"]

  static modal

  initialize() {
    // addAction(this, "turbo:frame-load->modal#contentLoaded")
    addAction(this, "show.bs.modal->modal#showPlaceholder")
    addAction(this, "turbo:submit-end->modal#submitEnd")
    addAction(this, "turbo:before-fetch-request->modal#show")
  }

  connect() {
    this.modal = Modal.getOrCreateInstance(this.element)
  }

  showPlaceholder() {
    this.contentTarget.innerHTML = this.defaultContentTarget.innerHTML
  }

  show() {
    this.modal.show()
  }

  disconnect() {
  }

  submitEnd(event) {
    if (event.detail.success) {
      this.modal.hide()
    }
  }
}


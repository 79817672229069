import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hint", "select"]
  static values = {
    list: Object
  }

  connect() {
    this.changed()
  }

  changed() {
    let value = this.selectTarget.value
    let text = this.listValue[value]

    if (!text) {
      text = ""
    }

    this.hintTarget.innerHTML = text
  }
}

function addAction(instance, action) {
  let previous = instance.element.getAttribute("data-action")
  if (previous == null) {
    previous = ""
  }

  instance.element.setAttribute("data-action", previous + " " + action)
}

export { addAction }

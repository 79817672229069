import { Application } from "@hotwired/stimulus"
import Clipboard from 'stimulus-clipboard'
import PlacesAutocomplete from 'stimulus-places-autocomplete'
import NestedForm from 'stimulus-rails-nested-form'

const application = Application.start()

application.register('clipboard', Clipboard)
application.register('places', PlacesAutocomplete)
application.register('nested-form', NestedForm)

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

import { Controller } from "@hotwired/stimulus"
import { addAction } from "../src/helpers"

export default class extends Controller {
  static targets = ['to']
  static values = {
    id: String,
  }

  connect() {
    addAction(this, "click->delegate-click#delegate")
  }

  delegate(event) {
    event.preventDefault()
    event.stopPropagation()

    this.toTargets.forEach((target) => {
      target.click();
    })

    let target = document.getElementById(this.idValue)
    target.click();
  }
}

import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

// import morphdom from "morphdom"

// taken from https://github.com/hopsoft/cable_ready/blob/abc9677bc09dac9b3f1c370464389131dd87dd8a/javascript/cable_ready.js#L20
//
// SEE: https://github.com/patrick-steele-idem/morphdom#morphdomfromnode-tonode-options--node
// const shouldMorph = (fromEl, toEl) => {
//   // Skip nodes that are equal:
//   // https://github.com/patrick-steele-idem/morphdom#can-i-make-morphdom-blaze-through-the-dom-tree-even-faster-yes
//   if (fromEl.isEqualNode(toEl)) return false
//
//   let permanent = fromEl.closest(`[data-component-permanent]`)
//   if (permanent) return false
//
//   if (fromEl.dataset.controller) {
//     fromEl.outerHTML = toEl.outerHTML
//     return false
//   }
//   return true
// }

// const mergeDom = (fromEl, toEl) => {
//   morphdom(fromEl, toEl, {
//     childrenOnly: true,
//     onBeforeElUpdated: shouldMorph,
//     getNodeKey: function(node) {
//       return node.id;
//     },
//   });
// }

import { StreamActions } from "@hotwired/turbo"

StreamActions.replace_component = function () {
  let docFragment = this.templateContent;

  this.targetElements.forEach((elem) => {
    let controller = Stimulus.getControllerForElementAndIdentifier(elem, 'live-component')
    controller.update('', docFragment)
  });
}

export default class extends Controller {
  static values = {
    updateOnConnect: Boolean
  }

  get componentName() {
    return this.data.get('name')
  }

  connect() {
    // console.log('connect ' + this.componentName);
    let channels = []

    let channelsStr = this.data.get('channels')
    if (channelsStr.length > 0) {
      channels = JSON.parse(channelsStr)
    }

    if (channels.length > 0) {
      this.subscription = consumer.subscriptions.create(
        {
          channel: "LiveComponentChannel",
          channels: channels,
        },
        {
          received: this.received.bind(this),
          connected: () => {
            if (this.updateOnConnectValue) {
              this.ajaxUpdate()
            }
          },
        }
      )
    }
  }

  disconnect() {
    // console.log('disconnect ' + this.componentName);
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  update(html, docFragment) {
    this.replaceHTML(html, docFragment);
  }

  received(data) {
    if (data.hasOwnProperty('html')) {
      // mergeDom(this.element, data.html)
      this.replaceHTML(data.html)
    } else {
      this.ajaxUpdate()
    }
  }

  ajaxUpdate() {
    console.log('request ajax version')

    jQuery.ajax(this.data.get('url'), {
      method: 'POST',
      data: {
        args: this.data.get('args'),
      },
      success: (data) => {
        console.log('replacing from ajax ' + this.data.get('name'))
        this.replaceHTML(data)
      },
    })
  }

  replaceHTML(html, docFragment) {
    let endId = 'end-' + this.element.id
    let elem = this.element.nextSibling
    let lastElem

    while(true) {
      if (!elem || elem.id === endId) {
        break
      } else {
        lastElem = elem
        elem = elem.nextSibling
        lastElem.remove()
      }
    }
    this.element.insertAdjacentHTML('afterend', html)

    if (docFragment) {
      this.element.parentElement.insertBefore(docFragment, this.element.nextSibling)
    }
  }

  log(data) {
    console.log('LOG')
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['bar', 'secondsLeft', 'secondsPassed']

  connect() {
    this.originalTitle = document.title
    this.start = Date.parse(this.data.get('start')) + 1000
    this.finish = Date.parse(this.data.get('finish')) + 300
    this.pageTitle = this.data.get('pageTitle')

    this.renderBar()
    this.updateTitle()
    this.renderSeconds()

    this.intervalSeconds = setInterval(() => {
      this.renderSeconds()
      this.renderBar()
      this.updateTitle()
    }, 1000)
  }

  disconnect() {
    document.title = this.originalTitle

    if (this.intervalSeconds) {
      clearInterval(this.intervalSeconds)
    }
  }

  renderBar() {
    let now = new Date()
    let percent;
    let duration = this.finish - this.start

    if (now < this.finish) {
      this.secondsPassed = Math.floor((now - this.start) / 1000)
      this.secondsLeft = Math.floor((this.finish - now) / 1000)
      percent = (now - this.start) / duration * 100
    } else {
      this.secondsLeft = 0
      this.secondsPassed = Math.floor(duration / 1000)
      percent = 100
    }

    this.barTarget.style.width = percent + '%'
    this.barTarget.setAttribute('aria-valuenow', percent)
  }

  updateTitle() {
    document.title = this.formatTime(this.secondsLeft) + ' ' + this.pageTitle
  }

  renderSeconds() {
    this.secondsPassedTarget.innerHTML = this.formatTime(this.secondsPassed)
    this.secondsLeftTarget.innerHTML = this.formatTime(this.secondsLeft)
  }

  formatTime(seconds) {
    if (seconds < 0) {
      return '0:00'
    }
    let hours = Math.floor(seconds / 3600)
    let mins = Math.floor((seconds % 3600) / 60)
    let secs = (seconds % 60).toString().padStart(2, '0')

    if (hours > 0) {
      mins = mins.toString().padStart(2, '0')
      return `${hours}:${mins}:${secs}`
    } else {
      return `${mins}:${secs}`
    }
  }
}


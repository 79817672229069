import { Controller } from "@hotwired/stimulus"

// submit on enter
export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.keyDown, true)
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.keyDown)
  }

  keyDown(e) {
    if (e.keyCode === 13 && !(e.shiftKey || e.ctrlKey || e.altKey)) {
      e.preventDefault(); // Prevents the addition of a new line in the text field
      e.target.form.requestSubmit();
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"
import audioQueue from "../src/audio_queue"

async function morph(from, to) {
  await import("idiomorph")
  // from.innerHTML = to
  Idiomorph.morph(from, `<div>${to}</div>`, {morphStyle: "innerHTML"})
}

export default class extends Controller {
  static values = {
    id: String,
    zomPlayerId: String,
  }

  static targets = ["map", "mapOverlay", "players", "playersJson"]

  async connect() {
    await import("leaflet")

    this.subscription = consumer.subscriptions.create(
      {
        channel: "ZomGameChannel",
        zom_game_id: this.idValue,
        zom_player_id: this.zomPlayerIdValue,
      },
      {
        received: this.received.bind(this)
      }
    )

    var map = L.map(this.mapTarget)
    map.attributionControl.setPrefix(false)

    // https://wiki.openstreetmap.org/wiki/Raster_tile_providers
    // https://leaflet-extras.github.io/leaflet-providers/preview/

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: '',
      // attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
      tileSize: 512,
      minZoom: 6,
      maxZoom: 18,
      zoomOffset: -1,
      id: 'antulik/clnpt15vl00um01pv145yhawk',
      accessToken: 'pk.eyJ1IjoiYW50dWxpayIsImEiOiJjbG5vNnQ5aDMwZDJjMnFxdDF3djVraTc2In0.2uQPhpiMMqQHUnHpeWVTMQ'
    }).addTo(map);

    map.on('click', this.onMapClick.bind(this))

    // this.userCircle = L.circleMarker([51.508, -0.11], {
    //   color: 'red',
    //   fillColor: '#f03',
    //   fillOpacity: 0.5,
    //   radius: 10
    // }).addTo(map)

    this.map = map
    // zoom the map to the polygon
    // map.fitBounds(this.polygon.getBounds());

    this.positionWatchId = navigator.geolocation
      .watchPosition(this.onPosition.bind(this), this.onPositionError.bind(this), {enableHighAccuracy: true});

  }

  disconnect() {
    this.subscription.unsubscribe()

    if (this.positionWatchId) {
      navigator.geolocation.clearWatch(this.positionWatchId);
    }
  }

  setInitialMapView({y, x}) {
    if (!this.mapViewSet) {
      this.map.setView([y, x], 15)
      this.mapViewSet = true
    }
  }

  onPosition(position) {
    const crd = position.coords

    this.subscription.perform("update_location", {
      lat: crd.latitude,
      lng: crd.longitude,
    })
  }

  onPositionError(err) {
    console.error(`ERROR(${err.code}): ${err.message}`);
  }

  moveCircle(id, x, y, style = {}) {
    if (!x || !y) {
      console.log("nil coord for point")
      return
    }

    let circle = this.circles[id]

    if (circle) {
      circle.setLatLng([y, x])
      circle.setStyle(style)

    } else {
      circle = L.circleMarker([y, x], style).addTo(this.map)

      this.circles[id] = circle
    }
  }

  received(data) {
    console.log("Received")
    // console.log(data)
    this.circles ||= {}

    if (data.event_type === "state") {
      this.playersJsonTarget.innerHTML = JSON.stringify(data, null, 2)

      Object.values(this.circles).forEach((circle) => {
        circle.remove()
      })
      this.circles = {}

      data.players.forEach(
        ({id, x, y, zombie, current}) => {
          let style = {
            color: '#40ff00',
            fillColor: '#ffffff',
            fillOpacity: 0.9,
            radius: 10,
            interactive: false,
          }

          if (zombie) {
            style.color = 'red'
            style.fillColor = "#333"
          }

          if (current) {
            style.fillColor = "#0059ff"
            this.setInitialMapView({y, x})
            this.map.flyTo([y, x])
          }

          this.moveCircle(id, x, y, style)
        }
      )

      if (this.deathCircle) {
        this.deathCircle.remove()
      }
      if (data.death_circle) {
        let {x, y, radius} = data.death_circle
        this.deathCircle = L.circle([y, x], {
          radius: radius,
          color: 'red',
          fillColor: '#9a9a9a',
          fillOpacity: 0,
          weight: 5,
          interactive: false,
        }).addTo(this.map)
      }
    } else if (data.event_type === "render") {
      // console.log(data)
      if (data.players_html) {
        // this.playersTarget.innerHTML = data.players_html;
        morph(this.playersTarget, data.players_html)
      }

      if (data.map_overlay_html) {
        // this.mapOverlayTarget.innerHTML = data.map_overlay_html;
        morph(this.mapOverlayTarget, data.map_overlay_html)
      }
    } else if (data.event_type === "play") {
      // console.log(data)
      audioQueue.addUrl(data.url)

    } else if (data.event_type === "log") {
      console.log(data)
    }
  }

  onMapClick(e) {
    console.log("You clicked the map at " + e.latlng)
    this.subscription.perform("update_location", {
      lat: e.latlng.lat,
      lng: e.latlng.lng
    })

    // this.userCircle.setLatLng(e.latlng)
    // this.map.flyTo(e.latlng)
  }

}

import { Controller } from "@hotwired/stimulus"
import { set as setCookie, get as getCookie } from 'es-cookie'

export default class extends Controller {
  static values = {
    url: String,
  }
  static targets = ["dismiss"]

  connect() {
    this.requestPermission = this.requestPermission.bind(this)

    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification")
    } else if (this.isGranted()) {
      console.log("Permission to receive notifications has been granted")
      this.requestNew()
    } else if (Notification.permission === 'denied') {
      console.log("Permission denied")
    } else {
      if (getCookie('webpush') !== 'dismissed' || !this.hasDismissTarget) {
        this.show()
      }
    }
  }

  isGranted() {
    return Notification.permission === "granted"
  }

  show() {
    this.element.classList.remove("d-none")
  }

  hide() {
    this.element.classList.add("d-none")
  }

  requestPermission(e) {
    e.preventDefault()

    this.requestNew(true)
  }

  dismiss(e) {
    this.hide()
    setCookie('webpush', 'dismissed', {expires: 14})
  }

  async requestNew(userAction) {
    if (!userAction && !this.isGranted()) {
      return
    }

    let url = this.urlValue

    let registration = await navigator.serviceWorker.ready;

    // Use the PushManager to get the user's subscription to the push service.
    let subscription = await registration.pushManager.getSubscription()

    subscription ||= await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: window.vapidPublicKey
    })

    $.post(url, {
      data: subscription.toJSON(),
    }).then((data) => {
      this.hide()
    })
  }
}


import { Controller } from "@hotwired/stimulus"

// https://select2.org/configuration/options-api
export default class extends Controller {
  initialize() {
  }

  async connect() {
    await import("select2/dist/css/select2.min.css")
    const $ = (await import('jquery')).default
    const select2 = (await import("select2/dist/js/select2.js")).default

    select2($);

    jQuery(this.element).select2({
      tags: true,
      tokenSeparators: [','],
      maximumInputLength: 40
    });
  }

  disconnect() {
    jQuery(this.element).select2('destroy')
  }
}

import { Controller } from "@hotwired/stimulus"
import { addAction } from "../src/helpers"
export default class extends Controller {
  connect() {
    if (history.length > 0) {
      this.show()
      addAction(this, "click->back-button#back")
    } else {
      this.hide()
    }
  }

  back(e) {
    history.go(-1)
    e.preventDefault()
  }

  hide() {
    this.element.classList.add("d-none")
  }

  show() {
    this.element.classList.remove("d-none")
  }
}


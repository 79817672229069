// https://css-tricks.com/how-to-recreate-the-ripple-effect-of-material-design-buttons/
function handle(event) {
  const el = event.target.closest(".card-ripple")

  if (el) {
    // console.log("ripple")
    // console.log(el)

    const circle = document.createElement("span");
    const diameter = Math.max(el.clientWidth, el.clientHeight);
    const radius = diameter / 2;
    const viewportOffset = el.getBoundingClientRect();

    // console.log({
    //   "el.offsetLeft": el.offsetLeft,
    //   "el.offsetTop": el.offsetTop,
    //   "event.clientX": event.clientX,
    //   "event.clientY": event.clientY,
    //   "viewportOffset.top": viewportOffset.top,
    //   "viewportOffset.left": viewportOffset.left,
    // })

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - viewportOffset.left - radius}px`;
    circle.style.top = `${event.clientY - viewportOffset.top - radius}px`;
    circle.classList.add("ripple");
    circle.dataset.turboTemporary = "true"

    const ripples = document.getElementsByClassName("ripple");
    for (let ripple of ripples) {
      ripple.remove();
    }

    el.appendChild(circle);
  }
}

document.addEventListener('click', handle)

import '@hotwired/turbo-rails'

// https://github.com/hotwired/turbo/pull/1072/files
// This is a patch to morph on "advance" page visits, otherwise cache restore doesn't work
Turbo.session.view.isPageRefresh = (visit) => {
  return !visit || (Turbo.session.view.lastRenderedLocation.pathname === visit.location.pathname)
}



// addEventListener("turbo:render", (event) => {
//   console.log(event.detail)
//   console.log(event)
// })
//
// addEventListener("turbo:before-render", (event) => {
//   console.log(event)
// })



// import "idiomorph"

// import { application } from "../controllers/application"

// https://github.com/hotwired/turbo/issues/951#issuecomment-1656824723
// async function nextEventLoopTick() {
//   return new Promise((resolve) => setTimeout(() => resolve(), 0));
// }

// ISSUE: turbo frame stopped working
// https://github.com/hotwired/turbo/issues/951#issuecomment-1656824723
// addEventListener("turbo:before-render", (event) => {
//   event.detail.render = async (currentElement, newElement) => {
//     console.log("render")
//     console.log(currentElement)
//     console.log(newElement)
//     await nextEventLoopTick();
//     application.controllers.map((controller) => controller.disconnect());
//
//     await nextEventLoopTick();
//     Idiomorph.morph(currentElement, newElement);
//
//     await nextEventLoopTick();
//     application.controllers.map((controller) => controller.connect());
//   };
// });

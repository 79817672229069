
class AudioQueue {
  constructor() {
    this.urls = [];
    this.playing = false;
    this.audio = new Audio();
    this.audio.addEventListener("ended", () => {
      this.playing = false
      this.play()
    })
  }

  addSilence() {
    // https://stackoverflow.com/questions/31776548/why-cant-javascript-play-audio-files-on-iphone-safari
    // https://gist.github.com/novwhisky/8a1a0168b94f3b6abfaa
    this.addUrl("data:audio/mpeg;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4Ljc2LjEwMAAAAAAAAAAAAAAA/+M4wAAAAAAAAAAAAEluZm8AAAAPAAAAAwAAAbAAqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV////////////////////////////////////////////AAAAAExhdmM1OC4xMwAAAAAAAAAAAAAAACQDkAAAAAAAAAGw9wrNaQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/+MYxAAAAANIAAAAAExBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV/+MYxDsAAANIAAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV/+MYxHYAAANIAAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV")
  }

  addUrl(url) {
    this.urls.push(url)
    this.play()
  }

  async play() {
    if (this.playing) { return }

    let url = this.urls.shift();
    let result;

    if (url) {
      this.audio.src = url
      this.playing = true
      try {
        result = await this.audio.play();
      } catch (error) {
        console.log(error)
        // no permission to play
        this.playing = false
        this.urls.unshift(url)
      }
    }

    return result
  }
}

const audioQueue = new AudioQueue();
window.audioQueue = audioQueue

export default audioQueue;

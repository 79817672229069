  import { Controller } from "@hotwired/stimulus"

  export default class extends Controller {
    static targets = ["count"]
    static values = {
      voted: Boolean
    }

    connect() {
      this.render()
    }

    vote(e) {
      let count = Number.parseInt(this.countTarget.innerHTML)

      this.element.dataset.turbo = 'true'
      if (this.votedValue) {
        count -= 1
        this.votedValue = false
        this.element.dataset.turboMethod = 'delete'
      } else {
        count += 1
        this.votedValue = true
        this.element.dataset.turboMethod = 'post'
      }

      this.countTarget.innerHTML = count
      this.render()
    }

    render() {
      let voted = this.votedValue

      $(this.countTarget).toggleClass('bg-light-primary text-dark', !voted)
      $(this.countTarget).toggleClass('bg-primary', voted)
    }
  }

import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.css"

flatpickr.l10ns.default.firstDayOfWeek = 1; // Monday

export default class extends Controller {
  connect() {
    this.flatpickr = flatpickr(this.element, {
      disableMobile: true,
      altInput: true,
      altFormat: "l, j F",
      minDate: new Date(),
      allowInvalidPreload: true,
      monthSelectorType: "static",
    })
  }

  disconnect() {
    this.flatpickr.destroy()
  }
}


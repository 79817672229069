import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = {
    url: String,
    userSgid: String,
  }

  get enabled() {
    return window.CapJS && window.CapJS.Browser
  }

  connect() {
    if (!this.enabled) {
      return
    }
    this.idValue = this.makeid(16)

    this.subscription = consumer.subscriptions.create(
      {
        channel: "WormholeChannel",
        id: this.idValue,
      },
      {
        received: this.received.bind(this)
      }
    )
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  submit(e) {
    if (!this.enabled) {
      return
    }
    console.log(e)
    e.preventDefault()
    e.stopImmediatePropagation()

    let form = e.target
    let url = new URL(this.urlValue)
    let formParams = new URLSearchParams(new FormData(form))

    let params = Object.fromEntries(formParams)
    delete params.authenticity_token

    let wormhole = {
      id: this.idValue,
      user_sgid: this.userSgid,
      action: form.action.replace("//app.tribes", "//www.tribes"),
      method: form.method,
      data: params,
    }
    // console.log(wormhole)

    url.searchParams.append("wormhole", btoa(JSON.stringify(wormhole)))

    console.log("Opening in-app browser " + url.toString())

    CapJS.Browser.open({
      url: url.toString(),
      presentationStyle: 'popover'
    })
  }

  received(data) {
    console.log(data)

    CapJS.Browser.close()

    Turbo.visit(data.url)
  }

  // https://stackoverflow.com/a/1349426/987533
  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
}


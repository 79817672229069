import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.lock()
  }

  disconnect() {
    if (this.wakelock) {
      this.wakelock.release()
      this.wakelock = null
    }
  }

  async lock(e) {
    if (navigator.wakeLock) {
      this.wakelock = await navigator.wakeLock.request("screen");
    }
  }
}


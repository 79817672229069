
import { StreamActions } from "@hotwired/turbo"
StreamActions.log = function () {
  console.log(this.getAttribute("message"))
}

StreamActions.play = function () {
  const url = this.getAttribute("url")
  const audio = new Audio(url);
  audio.play();
}

import { Controller } from "@hotwired/stimulus"

function parseTime(str) {
  let [h, m] = str.split(":")
  return buildTime(h, m)
}

function buildTime(hours, minutes) {
  let date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  return date
}

function formatTime(d) {
  return ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
}

export default class extends Controller {
  static targets = ["start", "end"]
  static values = {
    durationMins: Number
  }

  get startTime() {
    return parseTime(this.startTarget.value)
  }

  get endTime() {
    return parseTime(this.endTarget.value)
  }

  connect() {
    this.renderEndList()
  }

  startChanged() {
    this.renderEndList()
  }

  endChanged() {
    this.durationMinsValue = (this.endTime - this.startTime) / 1000 / 60
  }

  renderEndList() {
    let startStr = this.startTarget.value
    let start = parseTime(startStr)
    // let endMax = start.setHours(start.getHours);

    let hrs24 = (24 * 60 * 60 * 1000)

    let elements = []
    let durationMin = 0

    var d = new Date(start.getTime())

    while (true) {
      let step = 15
      if (durationMin >= 60) {
        step = 30
      }
      durationMin += step
      d.setMinutes(d.getMinutes() + step)
      if ((d - start) >= hrs24) {
        break
      }

      let durationStr
      if (durationMin < 60) {
        durationStr = durationMin + " mins"
      } else {
        let num =  durationMin / 60
        let type = " hrs"
        if (num === 1) type = " hr"
        durationStr = num + type
      }
      let value = formatTime(d)
      let text = `${value} (${durationStr})`

      let option = document.createElement("option")
      option.value = value
      option.appendChild(document.createTextNode(text))
      if (durationMin === this.durationMinsValue) {
        option.selected = true
      }
      elements.push(option)
    }

    this.endTarget.replaceChildren(...elements)
  }
}


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static values = {
  //   open: Boolean
  // }

  connect() {
    this.element.click()
    // if (this.openValue !== false) {
    // }
  }
}

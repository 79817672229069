import { Controller } from "@hotwired/stimulus"
// import $script from "scriptjs";

export default class extends Controller {
  initialize() {
  }

  connect() {
    let name = this.data.get('userName')
    let email = this.data.get('userEmail')
    let room = this.data.get('roomName')

    const domain = 'meet.jit.si';
    const options = {
      roomName: room,
      width: '100%',
      height: '100%',
      parentNode: this.element,
      userInfo: {
        email: email,
        displayName: name,
      },
      configOverwrite: {
        disableInviteFunctions: true,
        enableInsecureRoomNameWarning: false,
        // noticeMessage: 'hello',
        // enableWelcomePage: false,
        // hideLobbyButton: true,
        prejoinPageEnabled: false,
        disableRemoteMute: true,
        // doNotStoreRoom: true,
        remoteVideoMenu: {
          // If set to true the 'Kick out' button will be disabled.
          disableKick: true
        },
        startWithAudioMuted: true,
      },
      interfaceConfigOverwrite: {
        TOOLBAR_ALWAYS_VISIBLE: true,
        TOOLBAR_TIMEOUT: 1000 * 3600 * 24,
        TOOLBAR_BUTTONS: [
          'microphone',
          'camera',
          'closedcaptions',
          'desktop',
          // 'embedmeeting',
          // 'fullscreen',
          'fodeviceselection',
          'hangup',
          // 'profile',
          'chat',
          // 'recording',
          // 'livestreaming',
          'etherpad',
          'sharedvideo',
          'settings',
          'raisehand',
          'videoquality',
          'filmstrip',
          // 'invite',
          // 'feedback',
          'stats',
          'shortcuts',
          'tileview',
          'videobackgroundblur',
          'download',
          // 'help',
          // 'mute-everyone',
          // 'security'
        ],
      }
    };
    this.jitsi = new JitsiMeetExternalAPI(domain, options);
  }

  disconnect() {
    this.jitsi.dispose()
  }
}

import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static targets = ["clicker"]
  static debounces = ["submit"]
  static values = {
    selector: {type: String, default: null},
    debounce: {type: Number, default: 300}
  }

  connect() {
    useDebounce(this, {wait: this.debounceValue})
  }
  click() {
    if (this.hasClickerTarget) {
      this.clickerTarget.click()
    }
    if (this.selectorValue) {
      document.querySelector(this.selectorValue).click()
    }
  }

  submit() {
    let submitButton = this.element.querySelector("input[type=submit]")
    if (submitButton) {
      submitButton.click()
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.element.requestSubmit();
  }
}
